import React from 'react';

const ServicesSection = () => {
  return (
    <div className="services-section" style={{ height: '100vh', backgroundColor: '#f0f0f0', padding: '4rem 2rem', textAlign: 'center' }}>
      <h2 style={{ fontSize: '2.5rem', color: '#333' }}>Our Services</h2>
      <p style={{ maxWidth: '600px', margin: '0 auto', color: '#555' }}>
        We specialize in creating immersive virtual reality experiences for buildings, allowing you to explore and visualize spaces like never before.
      </p>
    </div>
  );
};

export default ServicesSection;
