import React, { useEffect, useState } from 'react';
import './SplashScreen.css';

const SplashScreen = ({ onComplete }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
      onComplete();
    }, 3000); // Change this duration to control how long the splash screen stays visible

    return () => clearTimeout(timeout);
  }, [onComplete]);

  return (
    <div className={`splash-screen ${!isVisible ? 'fade-out' : ''}`}>
      <h1 className="splash-text">Welcome to VirtuFrames</h1>
    </div>
  );
};

export default SplashScreen;
