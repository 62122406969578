// src/components/MaintenancePage.js
import React from 'react';
import './MaintenancePage.css';  // Import the CSS file for styling

const MaintenancePage = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <h1>Site Under Maintenance</h1>
        <p>We’re currently working on something awesome. Please check back later!</p>
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default MaintenancePage;
