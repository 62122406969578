import React from 'react';
import { motion } from 'framer-motion';

const HeroSection = () => {
  return (
    <div className="hero-section" style={{ height: '100vh', background: '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <motion.h1
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ fontSize: '4rem', color: '#333' }}
      >
        Discover Virtual Buildings in 3D
      </motion.h1>
    </div>
  );
};

export default HeroSection;
