import React, { useState, useEffect } from 'react';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ServicesSection from './components/ServicesSection';
import ContactSection from './components/ContactSection';
import SplashScreen from './components/SplashScreen';
import Navbar from './components/Navbar';
import MaintenancePage from './components/MaintenancePage';

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true);

  // Manage splash screen visibility with a smooth transition
  useEffect(() => {
    const timer = setTimeout(() => setIsSplashVisible(false), 1000); // Splash screen lasts 3 seconds
    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, []);

  return (
    <>
      {isSplashVisible ? (
        <SplashScreen />
      ) : (
        <div className="app-container">
          <Navbar />

          <HeroSection />
          <MaintenancePage />
          <AboutSection />
          <ServicesSection />
          <ContactSection />
        </div>
      )}
    </>
  );
}

export default App;
