import React from 'react';

const ContactSection = () => {
  return (
    <div className="contact-section" style={{ height: '100vh', backgroundColor: '#d0d0d0', padding: '4rem 2rem', textAlign: 'center' }}>
      <h2 style={{ fontSize: '2.5rem', color: '#333' }}>Contact Us</h2>
      <p style={{ color: '#555' }}>Email us at: info@virtuframes.com</p>
      <button style={{ padding: '1rem 2rem', fontSize: '1rem', backgroundColor: '#333', color: '#fff', border: 'none', cursor: 'pointer' }}>
        Get in Touch
      </button>
    </div>
  );
};

export default ContactSection;
