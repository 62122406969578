// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/MaintenancePage.css */
.maintenance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(45deg, #050505, #0f5e45); /* Gradient background */
    color: #d0eeeb;
    font-family: 'Burt', sans-serif;
    text-align: center;
    position: relative;
  }
  
  .maintenance-content {
    z-index: 1;
  }
  
  .maintenance-content h1 {
    font-size: 4rem;
    margin-bottom: 0.5rem;
    animation: fadeIn 2s ease-in-out;
  }
  
  .maintenance-content p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    animation: fadeIn 2s ease-in-out 0.5s;
  }
  
  .spinner {
    border: 10px solid rgba(235, 232, 232, 0.2);
    border-radius: 50%;
    border-top: 10px solid #ffffff;
    width: 36px;
    height: 36px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @media (max-width: 768px) {
    .maintenance-content h1 {
      font-size: 2.5rem;
    }
  
    .maintenance-content p {
      font-size: 1.2rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MaintenancePage.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,WAAW;IACX,oDAAoD,EAAE,wBAAwB;IAC9E,cAAc;IACd,+BAA+B;IAC/B,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,gCAAgC;EAClC;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,qCAAqC;EACvC;;EAEA;IACE,2CAA2C;IAC3C,kBAAkB;IAClB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE,KAAK,UAAU,EAAE;IACjB,OAAO,UAAU,EAAE;EACrB;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;EACF","sourcesContent":["/* src/components/MaintenancePage.css */\n.maintenance-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100%;\n    background: linear-gradient(45deg, #050505, #0f5e45); /* Gradient background */\n    color: #d0eeeb;\n    font-family: 'Burt', sans-serif;\n    text-align: center;\n    position: relative;\n  }\n  \n  .maintenance-content {\n    z-index: 1;\n  }\n  \n  .maintenance-content h1 {\n    font-size: 4rem;\n    margin-bottom: 0.5rem;\n    animation: fadeIn 2s ease-in-out;\n  }\n  \n  .maintenance-content p {\n    font-size: 1.5rem;\n    margin-bottom: 2rem;\n    animation: fadeIn 2s ease-in-out 0.5s;\n  }\n  \n  .spinner {\n    border: 10px solid rgba(235, 232, 232, 0.2);\n    border-radius: 50%;\n    border-top: 10px solid #ffffff;\n    width: 36px;\n    height: 36px;\n    animation: spin 2s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  @keyframes fadeIn {\n    0% { opacity: 0; }\n    100% { opacity: 1; }\n  }\n  \n  @media (max-width: 768px) {\n    .maintenance-content h1 {\n      font-size: 2.5rem;\n    }\n  \n    .maintenance-content p {\n      font-size: 1.2rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
