import React from 'react';
import { motion } from 'framer-motion';

const AboutSection = () => {
  return (
    <div className="about-section" style={{ height: '100vh', backgroundColor: '#e0e0e0', padding: '4rem 2rem', textAlign: 'center' }}>
      <motion.h2
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ fontSize: '2.5rem', color: '#333' }}
      >
        About Virtuframes
      </motion.h2>
      <motion.p
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1.2 }}
        style={{ maxWidth: '600px', margin: '0 auto', color: '#555' }}
      >
        Virtuframes turns visionary buildings into virtual reality experiences. Explore the world of architecture in a way like never before.
      </motion.p>
    </div>
  );
};

export default AboutSection;
