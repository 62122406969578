import React from 'react';

const Navbar = () => {
  return (
    <nav className="navbar" style={{ position: 'fixed', top: 0, width: '100%', backgroundColor: '#fff', padding: '1rem', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <div className="navbar-brand" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#333' }}>
        Virtuframes
      </div>
    </nav>
  );
};

export default Navbar;
